import { PriceType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { PriceData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import price from '~/params/produit/price/index.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceProduitPrice: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params,
}) => {
  const { espaceId, produitId, priceId } = params;
  const priceData = new PriceData({
    espaceId,
    params: price,
    parentCollectionName: 'produits',
    parentId: produitId,
  });

  return (
    <TemplateEspace
      collectionName="prices"
      docId={priceId}
      espace={espace}
      user={user}
    >
      <CMSView>
        <Form<PriceType>
          displayAdd
          displayNavigation={false}
          displayView={false}
          docId={priceId}
          itemPathnamePrefix={`/espaces/${espaceId}/produits/${produitId}/prix/`}
          itemPathnameSuffix=""
          model={priceData}
          name="price"
          parentCollectionName="produits"
          parentId={produitId}
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceProduitPrice);
